import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const WeiterbildungKlartextPage = () => (
  <Layout>
    <SEO title="WeiterbildungKlartext" />

    <h1 id="ueberschrift">Lern- &amp; Verbesserungsangebot &emsp; Stand:&nbsp;{new Date().getFullYear()}</h1>

    <p className="note">!!!DIESER BEREICH BEFINDET SICH IM WIEDERAUFBAU!!!</p>

    <p style={{ fontSize: "1.2em", lineHeight: "1.7" }}>
          Kontakt:&ensp;
           <a href="tel:+49-9187-7081220" title="anrufen" style={{ fontSize: "1em"  }}>+49 (0) 91 87 / 70 81 220</a>
           &ensp;oder&ensp;
           <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu Seminaren und Workshops" title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
    </p>

    <p>
      Über diese Seite finden Sie die allgemeinen Daten zu einer Auswahl an Kursen. Weitere Kurse können auf Anfrage (wieder) ins Programm
      aufgenommen werden. Einige der Kurse können aktuell nur in einem kleinen Umfang angeboten werden. Hinweise hierzu finden Sie in&nbsp;den&nbsp;Kursdetails.<br />
      Konkrete Termine zu angesetzten (Mitmach-)Kursen und "(Arbeits-)Kreisen" finden Sie (inklusive Details)
      unter dem <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" style={{fontSize: "1em"}}>Klartext&nbsp;zu&nbsp;Aktuelles</Link>.
    </p>
    <p>
      Wenn Sie einen vorhandenen Kurs buchen oder einen individuellen Termin / Kurs vereinbaren möchten,
      dann benutzen Sie bitte die jeweils direkt bei dem Kurs angegebene Kontaktmöglichkeit. 
      Um bezüglich zusätzlicher Kurse anzufragen, nutzen Sie bitte die oben angegebene Kontaktmöglichkeit.
    </p>
    <p>
      Bitte senden Sie für die Anfrage nach einem individuellen Termin so viele Daten wie möglich.<br />
       (z.&nbsp;B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)<br />
      Der jeweilige Preis eines Kurses ist von extrem vielen Faktoren abhängig.<br />
       (wie z.&nbsp;B.: Raummiete, Versicherungen, Fahrtkosten, Teilnehmerzahlen, etc.)<br />
      Daher können die Preise bei scheinbar gleichen Kursen stark variieren.
    </p>
    <p>
      +++ Kein Teilnehmer muss mehr als unbedingt notwendig zahlen +++
    </p>

      {/**
        => (Gruppen-)Preise und Zeitbedarf   NUR   unter Terminen/Aktuelles!
        Überlegen wegen "Preisbausteinen" und "Vergünstigungen" z.B. Familientarife oder so
      */}
    <h2>Backen</h2>

      <Link to="/fdml_5b_w11_GB-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Gemeinsam Backen: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w11_GB-02-Plätzchen#ueberschrift" title="zu den Kursdetails">Gemeinsam Backen: Weihnachtszeit = Plätzchenzeit?!</Link>
      <br />
      <Link to="/fdml_5b_w11_GB-03-Buttercreme#ueberschrift" title="zu den Kursdetails">Gemeinsam Backen: Buttercreme-Verzierungen</Link>
      <p></p>

    <h2>Basteln</h2>
      Siehe: <Link to="/fdml_5a_weiterbildung-klartext#w02_KG">Kreatives Gestalten</Link>
      <p></p>

    <h2>Eigenorganisation</h2>

      <Link to="/fdml_5b_w01_EAT-01-EATBasis#ueberschrift" title="zu den Kursdetails">Eigenorganisation Spezial: Eigenorganisation, Arbeitsteilung, Teamarbeit</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-02-EigeneOrganisation#ueberschrift" title="zu den Kursdetails">Eigenorganisation: Was ist das? Wie kann ich vorgehen?</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-03-Familie#ueberschrift" title="zu den Kursdetails">Eigenorganisation im Team Familie: <b><u>Wer</u></b> macht <b><u>was</u></b> <b><u>wie</u></b> und vor allem <b><u>wann</u></b>?</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-04-Arbeit#ueberschrift" title="zu den Kursdetails">Eigenorganisation bei der Arbeit: <b><u>Wer</u></b> macht <b><u>was</u></b> <b><u>wie</u></b> und vor allem <b><u>wann</u></b>?</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-05-Merkhilfen#ueberschrift" title="zu den Kursdetails">Eigenorganisation Spezial: Wie schaffe ich Merkhilfen?</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-06-InneresSelbst#ueberschrift" title="zu den Kursdetails">Eigenorganisation II: Wie organisiere ich mein "Inneres Selbst"?</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-07-Vergessen#ueberschrift" title="zu den Kursdetails">Eigenorganisation II: Die Kunst des Vergessens</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-08-Aufgabentabelle#ueberschrift" title="zu den Kursdetails">Eigenorganisation II: Wie gestalte ich meine Wunschliste / Aufgabentabelle</Link>
      <br />
      <Link to="/fdml_5b_w01_EAT-09-Ueberraschung#ueberschrift" title="zu den Kursdetails">Eigenorganisation II: Wie plane ich „Unvorhergesehenes“?</Link>
      <p></p>

    <h2>Handarbeiten</h2>
      Siehe: <Link to="/fdml_5a_weiterbildung-klartext#w02_KG">Kreatives Gestalten</Link>
      <p></p>

    <h2>Haushalt</h2>

      <Link to="/fdml_5b_w10_HH-01-OrdnungVSSeele#ueberschrift" title="zu den Kursdetails">Haushalt: Ist Ordnung ein Spiegel der Seele?</Link>
      <br />
      <Link to="/fdml_5b_w10_HH-02-Aufraeumen#ueberschrift" title="zu den Kursdetails">Haushalt: Die Kunst des Aufräumens</Link>
      <br />
      <Link to="/fdml_5b_w10_HH-03-OmasTipps#ueberschrift" title="zu den Kursdetails">Haushalt: Omas Tipps und Tricks</Link>
      <p></p>

    <h2>Kochen</h2>

      <Link to="/fdml_5b_w08_GK-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-02-Japanisch#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Japanisch</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-03-SushiVSSashimi#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Sushi vs Sashimi</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-04-Mediterran#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Mediterran</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-05-Wok#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Wok-Gerichte</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-06-Fraenkisch#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: die fränkische Küche</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-07-Diaetetik#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: die chinesische Diätetik</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-08-Haeppchen#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Häppchen</Link>
      <br />
      <Link to="/fdml_5b_w08_GK-09-Desserts#ueberschrift" title="zu den Kursdetails">Gemeinsam Kochen: Desserts</Link>
      <p></p>


    <h2 id="w02_KG">Kreatives Gestalten</h2>

      <Link to="/fdml_5b_w09_KG-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-02-Haekeln00#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Häkeln für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-03-Stricken00#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Stricken für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-04-Kloeppeln00#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Klöppeln für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-05-Weben00#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Weben für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-06-Spinnen00#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Spinnen für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-07-Laternen#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Laternen basteln für Groß und Klein</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-08-Karten#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: (Gruß-)Karten basteln für Groß und Klein</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-09-Origami#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Origami für Groß und Klein</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-10-Schnitzen#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Schnitzen leicht gemacht</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-11-Steckstuhl#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Steckstühle leicht gemacht</Link>
      <br />
      <Link to="/fdml_5b_w09_KG-12-Kneten#ueberschrift" title="zu den Kursdetails">Kreatives Gestalten: Kneten leicht gemacht</Link>
      <p></p>

    <h2>Lesen</h2>

      <Link to="/fdml_5b_w07_GL-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w07_GL-02-Anfaenger#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: diverse Genres für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w07_GL-03-Lesegruppe#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: diverse Genres für Fortgeschrittene</Link>
      <br />
      <Link to="/fdml_5b_w07_GL-04-Kinderbuch#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: Kinderbücher des Vorschulalters</Link>
      <br />
      <Link to="/fdml_5b_w07_GL-05-Ueben#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: Gemeinsam Üben</Link>
      <br />
      <Link to="/fdml_5b_w07_GL-06-Englisch#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: englische Geschichten</Link>
      <br />
      <Link to="/fdml_5b_w07_GL-07-Hieroglyphen#ueberschrift" title="zu den Kursdetails">Gemeinsam Lesen: ägyptische Hieroglyphen I</Link>
      <p></p>

    <h2>Lernkreise</h2>

      <Link to="/fdml_5b_w05_LK-01-Reiterabzeichen#ueberschrift" title="zu den Kursdetails">Lernkreis: Reiterabzeichen </Link>
      <br />
      <Link to="/fdml_5b_w05_LK-02-Schulfach#ueberschrift" title="zu den Kursdetails">Lernkreis: diverse Schulfächer</Link>
      <br />
      <Link to="/fdml_5b_w05_LK-03-CTFL#ueberschrift" title="zu den Kursdetails">Lernkreis: ISTQB® Certified Tester: Foundation Level</Link>
      <br />
      <Link to="/fdml_5b_w05_LK-04-CTTM#ueberschrift" title="zu den Kursdetails">Lernkreis: ISTQB® Certified Tester: Advanced Level: Testmanager</Link>
      <br />
      <Link to="/fdml_5b_w05_LK-05-CTTA#ueberschrift" title="zu den Kursdetails">Lernkreis: ISTQB® Certified Tester: Advanced Level: Test Analyst</Link>
      <br />
      <Link to="/fdml_5b_w05_LK-06-CTAT#ueberschrift" title="zu den Kursdetails">Lernkreis: ISTQB® Certified Tester: Agile Tester</Link>
      {/*<br />
      <Link to="/fdml_5b_w05_LK-07-Fischerschein#ueberschrift" title="zu den Kursdetails">Lernkreis: staatliche Fischerprüfung Bayern</Link>
      */}
      <p></p>

    <h2>Line Dance</h2>

      <Link to="/fdml_5b_w12_LD-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Line Dance: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w12_LD-02-Anfaenger#ueberschrift" title="zu den Kursdetails">Line Dance: Anfängerkurs</Link>
      <br />
      <Link to="/fdml_5b_w12_LD-03-Ueben#ueberschrift" title="zu den Kursdetails">Line Dance: Übungskurs für bestimmte Tänze</Link>
      <p></p>

    <h2>Musik machen</h2>

      <Link to="/fdml_5b_w03_MM-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Musik machen: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w03_MM-02-Blockfloete#ueberschrift" title="zu den Kursdetails">Musik machen: Blockflöte für Anfänger</Link>
      <br />
      <Link to="/fdml_5b_w03_MM-03-Gitarre#ueberschrift" title="zu den Kursdetails">Musik machen: Gitarre für Anfänger</Link>
      <p></p>

    <h2>Rollenspiel (Fantasy)</h2>

      <Link to="/fdml_5b_w06_RS-01-Schnuppern#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: Schnuppertreff für Interessierte</Link>
      <br />
      <Link to="/fdml_5b_w06_RS-02-DSA#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: basierend auf DSA (dritte Edition)</Link>
      <br />
      <Link to="/fdml_5b_w06_RS-03-NeKarga#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: Ne-Karga</Link>
      <br />
      <Link to="/fdml_5b_w06_RS-04-yongenso#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: 四元素 (Yongenso)</Link>
      <br />
      <Link to="/fdml_5b_w06_RS-05-DnD3#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: basierend auf DnD (dritte Edition)</Link>
      <br />
      <Link to="/fdml_5b_w06_RS-06-DnD4#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: basierend auf DnD (vierte Edition)</Link>
      <br />
      <Link to="/fdml_5b_w06_RS-07-Niklas#ueberschrift" title="zu den Kursdetails">Fantasy-Rollenspiel: Kater Nik</Link>
      <p></p>

    <h2>Sprachen</h2>

      <Link to="/fdml_5b_w04_ER-01-Deutsch#ueberschrift" title="zu den Kursdetails">Einfach (mal) Reden: Deutsch</Link>
      <br />
      <Link to="/fdml_5b_w04_ER-02-Englisch#ueberschrift" title="zu den Kursdetails">Einfach (mal) Reden: Englisch</Link>
      <br />
      <Link to="/fdml_5b_w04_ER-03-Französisch#ueberschrift" title="zu den Kursdetails">Einfach (mal) Reden: Französisch</Link>
      <br />
      <Link to="/fdml_5b_w04_ER-04-Japanisch#ueberschrift" title="zu den Kursdetails">Einfach (mal) Reden: Japanisch</Link>
      <br />
      <Link to="/fdml_5b_w04_ER-05-Spanisch#ueberschrift" title="zu den Kursdetails">Einfach (mal) Reden: Spanisch</Link>
      <br />
      <Link to="/fdml_5b_w04_ER-06-Katalanisch#ueberschrift" title="zu den Kursdetails">Einfach (mal) Reden: Katalanisch</Link>
      <p></p>

    <h2>Tiere im Alltag</h2>

      <Link to="/fdml_5b_w02_TA-01-Erlebnisse#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Erlebnisse mit einem besonderen Partner</Link>
      <br />
      <Link to="/fdml_5b_w02_TA-02-Hund#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Der Hund</Link>
      <br />
      <Link to="/fdml_5b_w02_TA-03-Pferd#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Das Pferd und das Pony</Link>
      <br />
      <Link to="/fdml_5b_w02_TA-04-Barsch#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Achtung: Barsch!</Link>
      <br />
      <Link to="/fdml_5b_w02_TA-05-BettaSplendens#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Der Kampffisch (Betta)</Link>
      <br />
      <Link to="/fdml_5b_w02_TA-06-Wildtiere#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Der Umgang mit "Wildtieren"</Link>
      <br />
      <Link to="/fdml_5b_w02_TA-07-Ekelzeug#ueberschrift" title="zu den Kursdetails">Tiere im Alltag: Von Spinnen, Schlangen und anderem in Heim und Garten</Link>
      <p></p>

    <h2>Werken</h2>
      Siehe: <Link to="/fdml_5a_weiterbildung-klartext#w02_KG">Kreatives Gestalten</Link>
      <p></p>


    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>

  </Layout>
              )

              export default WeiterbildungKlartextPage
